import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CabecalhoTabela } from 'src/app/model/CabecalhoTabela';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, BASE_API } from 'src/environments/environment';
import Swal from 'sweetalert2';

//import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-cadastro-modulos-programa',
  templateUrl: './cadastro-modulos-programa.component.html',
  styleUrls: ['./cadastro-modulos-programa.component.scss']
})
export class CadastroModulosProgramaComponent implements OnInit, OnDestroy {

  //editor: Editor;

  public BASE_API = BASE_API;
  public selectedRowIndex: number;
  public selectedRowIndexEtapa: number;
  public selectedRowIndexAtividade: number;

  public arrayProgramas: any[];
  public programaSelecionado: any;

  public arrayModulos: any[];
  public moduloSelecionado: any;

  public editarItemSelecionado: any;

  public arrayEtapa: any[];
  public etapaSelecionado: any;

  public arrayAtividades: any[];
  public atividadeSelecionada: any;

  public nomeAtividade: string;
  public ordemAtividade: number;
  public obrigatorioAtividade: boolean;
  public ehEscala: boolean;
  public necessitaResposta: boolean;
  public descricaoAtividade: string;
  public ajuda: string;

  public urlMidia: string;
  public arrayCabecalho: CabecalhoTabela[];
  public midiaSelecionada: any;
  public arrayMidiaSelecionadas: any[];

  public formularioSelecionada: any;
  public habilitarFormAtividade: boolean;

  public objMensagens = {BoasVindas: "", Sucesso: "", Falha: "", Saida: ""}


  constructor(
    private ajax: AjaxService,
    public util: UtilService
  ) {
    this.habilitarFormAtividade = false;
    this.setMidia();
    this.limparAtividade();
  }

  limparAtividade() {
    this.nomeAtividade = "";
    this.ordemAtividade = 1;
    this.obrigatorioAtividade = true;
    this.ehEscala = false;
    this.necessitaResposta = false;
    this.descricaoAtividade = "";
    this.ajuda = "";
    this.formularioSelecionada = null;
    this.arrayMidiaSelecionadas = [];
  }

  setHabilitaFormCadAtividade() {
    this.limparAtividade();
    this.habilitarFormAtividade = true;
    this.atividadeSelecionada = null;
  }

  backHabilitaFormCadAtividade() {
    this.habilitarFormAtividade = false;
  }

  setMidia() {

    this.urlMidia = 'Midia/Midia';
    this.arrayCabecalho = [
      { atributo: 'titulo', label: 'TITULO', tamanho: '2', acao: false, align: "left" },
      { atributo: 'tipO_MIDIA_DESCRICAO', label: 'TIPO', tamanho: '1', acao: false, align: "left" },
      { atributo: 'contenT_TYPE', label: 'EXTENSÃO', tamanho: '1', acao: false, align: "left" },
      { atributo: 'nome', label: 'ARQUIVO', tamanho: '1', acao: false, align: "left" },
      {
        atributo: '', label: '', tamanho: '2', acao: true, align: "center",
        listAcao: [
          { atributo: '', label: 'SELECIONAR', tamanho: '1', acao: true, align: "center" }
        ]
      }


    ];
  }

  ngOnInit() {
    //this.editor = new Editor();
    //this.editor.commands.insertHTML('').focus().scrollIntoView().exec();
    this.selectedRowIndex = -1;
    this.selectedRowIndexEtapa = -1;
    this.selectedRowIndexAtividade = -1;
    this.arrayMidiaSelecionadas = [];
    this.buscar();
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    //this.editor.destroy();
  }


  removerFormulario() {
    this.formularioSelecionada = null;
  }

  removerMidia(item) {
    let index = this.arrayMidiaSelecionadas.findIndex(c => c.id == item.id);
    if (index > -1)
      this.arrayMidiaSelecionadas.splice(index, 1);
  }

  receberMidiaSelecionada(item, modal) {
    debugger
    this.arrayMidiaSelecionadas.push(item);
    this.closeModal(modal, "midia");
    console.log('receberMidiaSelecionada',this.arrayMidiaSelecionadas);
  }

  receberFormularioSelecionada(item, modal) {
    this.formularioSelecionada = item;
    this.closeModal(modal, "formulario");
    console.log('receberFormularioSelecionada',this.formularioSelecionada);
  }

  buscar() {
    this.util.showLoading();
    let url = API + "Programa/Programa";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayProgramas = this.util.convertIoptions(response.data, "iD_PROGRAMA", "descricao");
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }

  deletarModulo(item){
    Swal.fire({
      html: `Tem certeza que deseja apagar? <br>${item.titulo}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value != undefined && result.value != '') {
        this.desabilitarModulo(item);
      }
    });
  }

  desabilitarModulo(item){
    debugger
    this.util.showLoading();
    let url = API + "ModuloPrograma/ModuloPrograma/Desabilitar/"+item.iD_MODULO_PROGRAMA;
    return this.ajax.post(url,{})
      .subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Item desabilitado com sucesso");
        this.buscarModulos();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }

  deletarEtapa(item){
    Swal.fire({
      html: `Tem certeza que deseja apagar? <br>${item.titulo}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value != undefined && result.value != '') {
        this.desabilitarEtapa(item);
      }
    });
  }

  desabilitarEtapa(item){
    this.util.showLoading();
    let url = API + "EtapaPrograma/EtapaPrograma/Desabilitar/"+item.iD_ETAPA_PROGRAMA;
    return this.ajax.post(url,{})
      .subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Item desabilitado com sucesso");
        this.buscarEtapas();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }


  deletarAtividade(item){
    Swal.fire({
      html: `Tem certeza que deseja apagar? <br>${item.titulo}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value != undefined && result.value != '') {
        this.desabilitarAtividade(item);
      }
    });
  }

  desabilitarAtividade(item){
    this.util.showLoading();
    let url = API + "AtividadePrograma/AtividadePrograma/Desabilitar/"+item.iD_ATIVIDADE_PROGRAMA;
    return this.ajax.post(url,{})
      .subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Item desabilitado com sucesso");
        this.buscarAtividades();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }



  selectModulo(item) {
    this.selectedRowIndex = item.id;
    this.moduloSelecionado = item;
    this.buscarEtapas();
  }

  selectAtividade(item) {
    this.selectedRowIndexAtividade = item.id;
    this.atividadeSelecionada = item;
    console.log('atividade selecionada', this.atividadeSelecionada);
    this.habilitarFormAtividade = true;
    this.preencheAtividade(this.atividadeSelecionada);
  }

  preencheAtividade(atividadeSelecionada){
    this.nomeAtividade = atividadeSelecionada.titulo;
    this.descricaoAtividade = atividadeSelecionada.descricao;
    this.obrigatorioAtividade = atividadeSelecionada.obrigatorio;
    this.ehEscala = atividadeSelecionada.escala;
    this.necessitaResposta = atividadeSelecionada.necessitA_RESPOSTA;
    this.ordemAtividade = atividadeSelecionada.ordem;
    this.ajuda = atividadeSelecionada.ajuda;

    this.arrayMidiaSelecionadas = atividadeSelecionada.midias;
    this.formularioSelecionada = atividadeSelecionada.formulario;
  }

  selectEtapa(item) {
    this.selectedRowIndexEtapa = item.id;
    this.etapaSelecionado = item;
    this.buscarAtividades();
  }

  openModal(modal, item=null) {
    debugger
    if(item != null)
      this.editarItemSelecionado = item;
    else
      this.editarItemSelecionado = null;

    modal.show();
    //console.log("mostrou modal"), item;
  }

  closeModal(modal, nome) {
    modal.hide();
    console.log('fechou modal');
    if (nome == 'modulo'){
      this.buscarModulos();
    }
    else if(nome == 'etapa'){
      this.buscarEtapas();
    }

  }

  buscarModulos() {
    this.moduloSelecionado = null;
    this.etapaSelecionado = null;
    this.arrayAtividades = [];

    if (this.programaSelecionado != undefined && this.programaSelecionado != "") {
      this.util.showLoading();
      let url = API + "ModuloPrograma/ModuloPrograma/ConsultarModuloPrograma?ID_PROGRAMA=" + this.programaSelecionado;
      return this.ajax.get(url)
        .subscribe(response => {
          console.log(response);
          this.arrayModulos = response.data;
          this.util.closeLoading();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Buscar Modulo");
        });
    }
  }

  buscarEtapas() {
    debugger
    this.etapaSelecionado = null;
    this.arrayAtividades = [];

    if (this.moduloSelecionado != undefined && this.moduloSelecionado != null) {
      this.util.showLoading();
      let url = API + "EtapaPrograma/EtapaPrograma/ConsultarEtapaPrograma?ID_MODULO_PROGRAMA=" + this.moduloSelecionado.id;
      return this.ajax.get(url)
        .subscribe(response => {
          console.log(response);
          this.arrayEtapa = response.data;
          this.util.closeLoading();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Buscar Etapas");
        });
    }
  }

  buscarAtividades() {
    this.limparAtividade();
    this.backHabilitaFormCadAtividade();
    if (this.etapaSelecionado != undefined && this.etapaSelecionado != null) {
      this.util.showLoading();
      let url = API + "AtividadePrograma/AtividadePrograma/ConsultarAtividadePrograma?ID_ETAPA_PROGRAMA=" + this.etapaSelecionado.id;
      return this.ajax.get(url)
        .subscribe(response => {
          console.log(response);
          this.arrayAtividades = response.data;
          this.util.closeLoading();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Buscar Etapas");
        });
    }
  }

  validarAtividade(): boolean {

    if (this.programaSelecionado == undefined || this.programaSelecionado == null) {
      this.util.toasterShowError("Atenção", "Escolha o Programa");
      return false;
    }

    if (this.moduloSelecionado == undefined || this.moduloSelecionado == null) {
      this.util.toasterShowError("Atenção", "escolha o Módulo");
      return false;
    }

    if (this.etapaSelecionado == undefined || this.etapaSelecionado == null) {
      this.util.toasterShowError("Atenção", "escolha a Etapa");
      return false;
    }

    if (this.nomeAtividade == undefined || this.nomeAtividade == "") {
      this.util.toasterShowError("Atenção", "Entre com nome da Atividade");
      return false;
    }

    if (this.ordemAtividade == undefined || this.ordemAtividade == 0) {
      this.util.toasterShowError("Atenção", "Entre com a ordem da Atividade");
      return false;
    }

    return true;
  }

  salvarAtividade() {
    if (this.validarAtividade()) {
      let dados = this.montarJson();
      console.log(dados);
      this.util.showLoading();
      let url = API + "AtividadePrograma/AtividadePrograma/Salvar";
       this.ajax.post(url, dados)
        .subscribe(response => {
          console.log(response);

          this.util.closeLoading();
          if(!response.hasErrors){
            this.util.toasterShowSuccess("Atenção", "Atividade Salva com Sucesso");
          this.backHabilitaFormCadAtividade();
          this.limparAtividade();
          this.buscarAtividades();
          }else{
            this.util.toasterShowError("Atenção", response.message);
          }

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Buscar Atividade");
        });

    }
  }

  montarJson() {
    let dados = {
      "TITULO": this.nomeAtividade,
      "DESCRICAO": this.descricaoAtividade,
      "OBRIGATORIO": this.obrigatorioAtividade,
      "ESCALA": this.ehEscala,
      "NECESSITA_RESPOSTA": this.necessitaResposta,
      "ORDEM": this.ordemAtividade,
      "ID_ETAPA_PROGRAMA": this.etapaSelecionado.id
    }

    debugger
    if(this.ajuda != "")
      dados['AJUDA'] = this.ajuda;

    if (this.formularioSelecionada != undefined && this.formularioSelecionada != null)
      dados['ID_TRIAGEM'] = this.formularioSelecionada.id;

    if (this.arrayMidiaSelecionadas != undefined && this.arrayMidiaSelecionadas.length > 0)
      dados['ARRAY_MIDIAS'] = this.convertIdMidias(this.arrayMidiaSelecionadas);

    if(this.atividadeSelecionada != null)
      dados['ID_ATIVIDADE_PROGRAMA'] = this.atividadeSelecionada.iD_ATIVIDADE_PROGRAMA;

    return dados;
  }

  convertIdMidias(arrayMidias: any[]) {
    let dados = this.arrayMidiaSelecionadas.map(item => item.id);
    return dados;
  }

}
