// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import createNumberMask from "text-mask-addons/dist/createNumberMask";


export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCTmFJgRxGXvaflthWKBfzXB8KrXwXTx4w",
    authDomain: "gradual-social.firebaseapp.com",
    databaseURL: "https://gradual-social-default-rtdb.firebaseio.com",
    projectId: "gradual-social",
    storageBucket: "gradual-social.appspot.com",
    messagingSenderId: "475868525760",
    appId: "1:475868525760:web:bb4a58fc25c36b659be925",
    measurementId: "G-YL6KYGWCYD"
  }
};

export const SecretToken = 'ab1b46a7-2f9a-416b-b0c1-ec67ad19cf96';


export const REDIRECT_SITE = "https://www.facebook.com/grupo.gradual";

export const BASE_PATH = "https://app.grupogradual.com.br";
export const API_QRCODE = BASE_PATH + "/g/";

/////////////////////////////////////////////////////
export const ehDesenvolvimento = false;
/////////////////////////////////////////////////////

export const BASE_API = ehDesenvolvimento ? "https://localhost:44308" : "https://api.grupogradual.com.br";
export const BASE_API_MEETING = ehDesenvolvimento ? "https://localhost:44330/api/CoreBusiness/" : "https://api.meeting.lumago.com.br";

export const API =   BASE_API + "/api/";
export const IMG_PADRAO = BASE_PATH + "/banner/banner-padrao.gif";


export const TIPOS =  {EntradaDeDados:"39943721-e576-491a-80ab-59bce4e3c435"};

export const maskTelefoneCelular = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const maskTeleFixo = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/ ,'-', /\d/, /\d/];
export const maskCEP = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/ ];
export const numberMaskInt = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  //integerLimit: 8
});

export const numberMaskReal = createNumberMask({
  prefix: 'R$ ',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: true,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  //integerLimit: 8
});

export const TIPOS_ENTRADA =  {
  TextoLivre: 1
  , Telefone: 2
  , Cpf: 3
  , Rg: 4
  , Email: 5
  , Moeda: 6
  , Inteiro: 7
  , Selecao: 8
  , Check: 9
  , Radio: 10
  , Grade: 12
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
